import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';
import { BaseEntity } from './BaseEntity';

@Entity('ref_agence')
export class RefAgence extends BaseEntity {        // Typologie de l'installation Alerte

    apiMapping = {
        numUnite: 'numUniteAgence',
        orgSaIris: 'orgSaIris',
        orgSa: 'orgSa',
        libelle: 'libelle',
        address1: 'address1',
        address2: 'address2',
        address3: 'address3',
        address4: 'address4',
        postalCode: 'postalCode',
        villeCedex: 'villeCedex',
        commune: 'commune',
        tel: 'tel',
        fax: 'fax',
        email: 'email',
        fValide: 'fValide',
        codecofrac: 'codecofrac',
        numRegion: 'numRegion',
        libelleRegion: 'libelleRegion',
        numSociete: 'numSociete',
        libelleSociete: 'libelleSociete',
        codeFiliale: 'codeFiliale',
        libelleFiliale: 'libelleFiliale',
        numUniteMere: 'numUniteMere',
        libelleBaspage: 'libelleBaspage',
        noResp: 'noResp',
    };

    @PrimaryGeneratedColumn()
    id: number;

    @Column({ nullable: true })
    numUniteAgence: string;

    @Column({ nullable: true })
    orgSaIris: string;

    @Column({ nullable: true })
    orgSa: string;

    @Column({ nullable: true })
    libelle: string;

    @Column({ nullable: true })
    address1: string;

    @Column({ nullable: true })
    address2: string;

    @Column({ nullable: true })
    address3: string;

    @Column({ nullable: true })
    address4: string;

    @Column({ nullable: true })
    postalCode: string;

    @Column({ nullable: true })
    villeCedex: string;

    @Column({ nullable: true })
    commune: string;

    @Column({ nullable: true })
    tel: string;

    @Column({ nullable: true })
    fax: string;

    @Column({ nullable: true })
    email: string;

    @Column({ nullable: true })
    fValide: string;

    @Column({ nullable: true })
    codecofrac: string;

    @Column({ nullable: true })
    numRegion: number;

    @Column({ nullable: true })
    libelleRegion: string;

    @Column({ nullable: true })
    numSociete: number;

    @Column({ nullable: true })
    libelleSociete: string;

    @Column({ nullable: true })
    codeFiliale: string;

    @Column({ nullable: true })
    libelleFiliale: string;

    @Column({ nullable: true })
    numUniteMere: number;

    @Column({ nullable: true })
    libelleBaspage: string;

    @Column({ nullable: true })
    noResp: string;

    @Column({ nullable: true })
    isDeleted: boolean;
}
