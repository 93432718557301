import { Column, Entity, ManyToMany, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { RefMission } from './RefMission';
import { BaseEntity } from './BaseEntity';
import { RefChapterRubric } from './RefChapterRubric';

@Entity('ref_installation_family')
export class RefInstallationFamily extends BaseEntity {
  apiMapping = {
      id: 'id',
      name: 'name',
      parentId: {
        fieldName: 'parent',
        type: 'RefInstallationFamily'
      },
      refMissionId: {
        fieldName: 'refMission',
        type: 'RefMission'
      }
  };

  @PrimaryGeneratedColumn()
  id: number;

  @Column()
  name: string;

  @ManyToOne(type => RefInstallationFamily)
  parent: RefInstallationFamily;

  @ManyToOne(type => RefMission)
  refMission: RefMission;

  @Column({ nullable: true })
  isDeleted: boolean;

  @ManyToMany(type => RefChapterRubric, ref => ref.refInstallationFamilies)
  refChapterRubrics: RefChapterRubric[];
}
