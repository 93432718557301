export const environment = {
    production: true,
    offline: true,
    applicationCode: 'RAPSOMS',
    titleEnv: '',
    version: '8.0.0',

    dev: false,
    svpeUrl: 'https://WSRapsodieM.socotec.com/api/',
    apiUrl: 'https://RapsoMS-Mobile.socotec.com/api/',

    apiEndpoints: {
        version: 'version',
        checkVersion: 'version/{plateform}/{version}/{applicationCode}/latest',
        login: 'Authentication/login',
        token: 'Authentication/token',
        references: 'references',
        agencyReference: 'references/agence',
        verificationSearch: 'verifications/search',
        verificationImport: 'verifications/import',
        verificationCreate: 'verifications/create',
        verificationExport: 'verifications/export',
        verificationSync: 'verifications/synchronisation',
        pictureExport: 'verifications/exportPicture',
        annexExport: 'verifications/exportAnnex',
        sitePictureExport: 'verifications/exportSitePicture',
        businessRefSearch: 'verifications/GetListeDossiersEQT',
        listInterventions: 'verifications/getlistinterventions',
        detailIntervention: 'verifications/getdetailintervention',
        customerSiteRef: 'nomenclature/getcustomersites',
        searchNomenclature: 'nomenclature/GetNomenclaturesByLibelle',
        syncLog: 'user/updateusersynchronisationdate',
        pictureExists: 'verifications/pictureExists',
        annexExists: 'verifications/annexExists',
        sitePictureExists: 'verifications/sitePictureExists',
        updateLoginDate: 'user/UpdateUserLoginDate',
    },
};
