import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient,
              private storage: StorageService,
              private platform: Platform) { }

  async get(url: string, params: any = {}, isAuthenticated = true, baseUrl = environment.svpeUrl) {

    const requestParams = {
      params : params || {},
      headers: {}
    };

    if (isAuthenticated) {
      const user = await this.storage.get('user');
      requestParams.headers = {
        Authorization: `Bearer ${user.token || null}`,
        // device: (this.platform.is('android') || this.platform.is('iphone')) && !this.platform.is('mobileweb') ? 'SM' : 'WEB'
      };
    }

    return this.http.get(baseUrl + url, requestParams)
    .toPromise();
  }

  async post(url: string, body: any = {}, isAuthenticated = true, baseUrl = environment.svpeUrl) {

    const requestParams = body || {};
    const httpOptions = {
      headers: {}
    };

    if (isAuthenticated) {
      const user = await this.storage.get('user');
      httpOptions.headers = {
        Authorization: `Bearer ${user.token || null}`,
        // device: (this.platform.is('android') || this.platform.is('iphone')) && !this.platform.is('mobileweb') ? 'SM' : 'WEB'
      };
    }

    // if (url.includes('import') || url === 'verifications/synchronisation') {
    // lors de import (import/ synchro), send device type
    if (url.includes('import') ||
    (url.includes('synchronisation') && !url.includes('updateusersynchronisationdate'))) {
      httpOptions.headers['device'] = (this.platform.is('android') || this.platform.is('iphone')) && !this.platform.is('mobileweb') ? 'SM' : 'WEB';
    }

    return this.http.post(baseUrl + url, requestParams, httpOptions)
    .toPromise();
  }
}
