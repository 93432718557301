import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { Installation } from './Installation';
import { BaseEntity } from './BaseEntity';
import { Verification } from './Verification';
import { Building } from './Building';
import { Utils } from '../service/utils.service';

@Entity('note')
export class Note extends BaseEntity {
  apiMapping = {
    id: 'extId',
    title: 'title',
    description: 'description',
    createdDate: {
      type: 'Date',
      fieldName: 'createdDate',
    },
    updatedDate: {
      type: 'Date',
      fieldName: 'updatedDate',
    },
    isNew: 'isNew',
    isDeleted: 'isDeleted'
  };

  @PrimaryGeneratedColumn()
  id: number;

  @ManyToOne(type => Installation)
  installation: Installation;

  @ManyToOne(type => Building)
  building: Building;

  @ManyToOne(type => Verification, verification => verification.notes)
  @JoinColumn()
  verification: Verification;

  @Column({ nullable: true })
  @Index()
  extId: number;

  @Column({ nullable: true })
  title: string;

  @Column({ nullable: true })
  titleSearch: string;

  @Column({ nullable: true })
  description: string;

  @Column({ nullable: true })
  createdDate: number;

  @Column({ nullable: true })
  updatedDate: number;

  @Column({ nullable: true })
  isNew: boolean;

  @Column({ nullable: true })
  isDeleted: boolean;

  async fromApi(data: any, update = false) {
    await super.fromApi(data, update);
    this.titleSearch = Utils.removeAccents(this.title);
    return this;
  }
}
