import { Column, Entity, Index, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm';
import { Verification } from './Verification';
import { BaseEntity } from './BaseEntity';
import { Level } from './Level';
import { Installation } from './Installation';
import { Utils } from '../service/utils.service';
import { Observation } from './Observation';

@Entity('building')
export class Building extends BaseEntity {
  apiMapping = {
    id: 'extId',
    localId: 'id',
    isDeleted: 'isDeleted',
    isNew: 'isNew',
    name: 'name',
    order: 'position',
    levels: {
      fieldName: 'levels',
      type: 'Level',
      map: true,
      mapType: 'many'
    },
    installations: {
      fieldName: 'installations',
      type: 'Installation',
      map: true,
      mapType: 'many'
    },
    observations: {
      type: 'Observation',
      fieldName: 'observations',
      map: true,
      mapType: 'many',
    }
  };

  @PrimaryGeneratedColumn()
  id: number;

  @ManyToOne(() => Verification, verification => verification.buildings)
  @JoinColumn()
  verification: Verification;

  @OneToMany(() => Level, l => l.building, { cascade: true })
  levels: Level[];

  @OneToMany(() => Observation, o => o.building , { cascade: true })
  observations: Observation[];

  @OneToMany(() => Installation, i => i.building, { cascade: true })
  installations: Installation[];

  @Column({ nullable: true })
  @Index()
  extId: number;

  @Column({ nullable: true })
  name: string;

  @Column({ nullable: true })
  nameSearch: string;

  @Column({ nullable: true })
  isDeleted: boolean;

  @Column({ nullable: true })
  isNew: boolean;

  @Column({ nullable: true })
  position: number;

  async fromApi(data: any, update = false) {
    await super.fromApi(data, update);
    this.nameSearch = Utils.removeAccents(this.name);
    return this;
  }
}
