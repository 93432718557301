import { Column, Entity, JoinColumn, ManyToOne, OneToOne, PrimaryGeneratedColumn } from 'typeorm';
import { Verification } from './Verification';
import { RefSupervision } from './RefSupervision';
import { BaseEntity } from './BaseEntity';
import { RefWithAttendant } from './RefWithAttendant';

@Entity('preliminary_interview')
export class PreliminaryInterview extends BaseEntity {
  apiMapping = {
    hasMaterial: 'hasMaterial',
    refWithAttendantId: {
      fieldName: 'refWithAttendant',
      type: 'RefWithAttendant'
    },
    attendantName: 'attendantName',
    attendantJobName: 'attendantJobName',
    hasAccessProvided: 'hasAccessProvided',
    accessProvidedName: 'accessProvidedName',
    accessProvidedReason: 'accessProvidedReason',
    hasInstallationProvided: 'hasInstallationProvided',
    installationProvidedName: 'installationProvidedName',
    installationProvidedReason: 'installationProvidedReason',
    hasModifications: 'hasModifications',
    hasNotifications: 'hasNotifications',
    notificationsDetails: 'notificationsDetails',
    modificationsDetails: 'modificationsDetails',
    hasEvents: 'hasEvents',
    eventsDetails: 'eventsDetails',
    isSecurityRegistryShown: 'isSecurityRegistryShown',
    refSupervisionId: {
      fieldName: 'refSupervision',
      type: 'RefSupervision'
    },
    supervisionDetails: 'supervisionDetails'
  };

  @PrimaryGeneratedColumn()
  id: number;

  @OneToOne(type => Verification, v => v.preliminaryInterview, { eager: true })
  @JoinColumn()
  verification: Verification;

  @Column({ nullable: true })
  hasMaterial: boolean;

  @Column({ nullable: true })
  attendantName: string;

  @Column({ nullable: true })
  attendantJobName: string;

  @Column({ nullable: true })
  hasAccessProvided: boolean;

  @Column({ nullable: true })
  accessProvidedName: string;

  @Column({ nullable: true })
  accessProvidedReason: string;

  @Column({ nullable: true })
  hasInstallationProvided: boolean;

  @Column({ nullable: true })
  installationProvidedName: string;

  @Column({ nullable: true })
  installationProvidedReason: string;

  @Column({ nullable: true })
  hasModifications: boolean;

  @Column({ nullable: true })
  modificationsDetails: string;

  @Column({ nullable: true })
  hasEvents: boolean;

  @Column({ nullable: true })
  eventsDetails: string;

  @Column({ nullable: true })
  hasNotifications: boolean;

  @Column({ nullable: true })
  notificationsDetails: string;

  @Column({ nullable: true })
  isSecurityRegistryShown: boolean;

  @ManyToOne(type => RefSupervision, { eager: true })
  refSupervision: RefSupervision;

  @ManyToOne(type => RefWithAttendant, { eager: true })
  refWithAttendant: RefWithAttendant;

  @Column({ nullable: true })
  supervisionDetails: string;
}
