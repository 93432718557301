import {
    Column,
    Entity,
    JoinTable,
    ManyToMany,
    ManyToOne,
    PrimaryGeneratedColumn,
} from 'typeorm';
import { RefMission } from './RefMission';
import { BaseEntity } from './BaseEntity';
import { RefInstallationFamily } from './RefInstallationFamily';

@Entity('ref_chapter_rubric')
export class RefChapterRubric extends BaseEntity {
    apiMapping = {
        id: 'id',
        name: 'name',
        order: 'ordre',
        isDeleted: 'isDeleted',
        parentId: {
            fieldName: 'parent',
            type: 'RefChapterRubric'
        },
    };

    @PrimaryGeneratedColumn()
    id: number;

    @ManyToMany(type => RefMission, ref => ref.refChapterRubrics)
    @JoinTable()
    refMissions: RefMission[];

    @ManyToMany(type => RefInstallationFamily, ref => ref.refChapterRubrics)
    @JoinTable()
    refInstallationFamilies: RefInstallationFamily[];

    @Column()
    name: string;

    @Column()
    ordre: number;

    @Column()
    isDeleted: boolean;

    @ManyToOne(type => RefChapterRubric)
    parent: RefChapterRubric;

}
