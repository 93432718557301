import { Injectable } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  isValid = false;
  constructor(
    private api: ApiService,
    private platform: Platform,
    private appVersion: AppVersion) {
    this.isValidVersion(true).then((valid) => this.isValid = valid);
  }

  async isValidVersion(refresh = false) {
    if (!refresh) {
      return Promise.resolve(this.isValid);
    }
    const appVersion = await this.getVersion();

    let validVersion: any = true;
    if (this.platform.is('ios')) {
      validVersion = await this.checkVersion('ios', appVersion);
    } else if (this.platform.is('android')) {
      validVersion = await this.checkVersion('android', appVersion);
    }
    this.isValid = validVersion;
    return validVersion;
  }

  /**
   * Vérifier le version d'application
   * @param platform string Plaform
   * @param version string Numéro de version
   */
  checkVersion(platform: string, version: string) {
    let url = environment.apiEndpoints.checkVersion;
    url = url
      .replace('{plateform}', platform)
      .replace('{version}', version)
      .replace('{applicationCode}', environment.applicationCode);
    // console.log(url);
    return this.api.get(url, {}, false, environment.svpeUrl).catch(err => {
      console.log(err);
      return true;    // check version with server FAILED ==> no annoce ay all for version changement
    });
  }

  async getVersion() {
    let appVersion = environment.version;

    if (this.isMobileDevice()) {
      await this.appVersion.getVersionNumber().then((version) => {
        appVersion = version;
      });
    }

    return appVersion;
  }

  isMobileDevice() {
    return (this.platform.is('android') || this.platform.is('iphone')) && !this.platform.is('mobileweb');
  }
}
