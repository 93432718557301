import { Component, Input, OnInit, ViewEncapsulation, Directive } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit {
    @Input() title: string;
    @Input() content: any;
    @Input() buttons: any[] = [];
    @Input() no_default_close = false;
    @Input() ctrl: any;

    constructor(
        private modalController: ModalController,
        private domSanitizer: DomSanitizer,
    ) { }

    ngOnInit() {
        this.content = this.domSanitizer.bypassSecurityTrustHtml(this.content);

        this.buttons.forEach(button => {
            if (button.html) {

                // Permet d'afficher les icons dans les boutons
                button.html = this.domSanitizer.bypassSecurityTrustHtml(button.html);
            }
        });
    }

    /**
     * Permet de masquer la modal
     */
    dismiss() {
        this.modalController.dismiss();
        if (this.ctrl) {
            this.ctrl.exportOnceClicked = false;
            this.ctrl.duplicateClickOnce = false;
            this.ctrl.searchOnceClicked = false;
            this.ctrl.importOnceClicked = false
        }
    }
}
