import { Injectable } from '@angular/core';
import { File } from '@awesome-cordova-plugins/file/ngx';

@Injectable()
export class LogService {

    private fileName: string;
    private logsToWrite: string;
    private maxSize: number;

    constructor(
        private file: File
    ) {
        this.fileName = 'log.txt';
        this.logsToWrite = '';
        this.maxSize = 2000000;
        try {
            this.file.resolveLocalFilesystemUrl(this.file.externalApplicationStorageDirectory + this.fileName).then(fileEntry => {
                fileEntry.getMetadata(async (metaData) => {
                    // console.log(metaData.size);
                    if (metaData.size > this.maxSize) {
                        this.file.removeFile(this.file.externalApplicationStorageDirectory, this.fileName).then(() => {
                            this.file.createFile(this.file.externalApplicationStorageDirectory, this.fileName, true);
                        });
                    }
                });
            });
        } catch (e) {
            console.log('Log service not started');
        }
    }

    /**
     * Add some text to log (don't forget to commit)
     * @param text The text to log
     */
    addLogText(text: string) {
        // Add date and new line
        text = this.getDateTime() + ' ' + text + '\n';
        // Add to waiting list
        this.logsToWrite += text;
    }

    /**
     * Add object to log (don't forget to commit)
     * @param object The object to log
     */
    addLogObject(object: object) {
        const text = JSON.stringify(object);
        this.addLogText(text);
    }

    /**
     * Write logs in the log file
     */
    async commitLogs(): Promise<any> {
        // console.log('LOG', this.logsToWrite);
        // Create file if not exists or size > 2Mo
        try {
            const fileExists = await this.logFileExists();
            if (!fileExists) {
                await this.file.createFile(this.file.externalApplicationStorageDirectory, this.fileName, true);
                await this.writeText();
            } else {
                await this.writeText();
            }
        } catch (e) {
            // Catch if web version, or there's an error during log writting
            console.error('Can not write log file');
            this.logsToWrite = '';
        }
    }

    /**
     * Write text in file
     */
    private async writeText() {
        const blob = new Blob([this.logsToWrite], { type: 'text/plain' });
        await this.file.writeFile(this.file.externalApplicationStorageDirectory, this.fileName, blob, { replace: false, append: true });
        this.logsToWrite = '';
    }

    /**
     * Check if log file exists
     * @returns File exists
     */
    private async logFileExists(): Promise<boolean> {
        return this.file.checkFile(this.file.externalApplicationStorageDirectory, this.fileName).then(exists => {
            return exists;
        }).catch(err => {
            return false;
        });
    }

    /**
     * Get datetime infos to write in logs
     * @returns Formated datetime
     */
    private getDateTime(): string {
        const date = new Date();
        const day = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        const hour = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + ':' + date.getMilliseconds();
        const dateStr = '[' + day + ' ' + hour + ']';
        return dateStr;
    }
}
