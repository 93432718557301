import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Storage } from '@ionic/storage-angular';
import { Device } from '@ionic-native/device/ngx';
import { environment } from 'src/environments/environment';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { VersionService } from './version.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class LoginService {

    autheticated = false;

    constructor(
        private api: ApiService,
        private storage: StorageService,
        private device: Device,
        private appVersion: AppVersion,
        private versionService: VersionService
    ) {
        this.isAuthenticated(true).then((authenticated) => {
            this.autheticated = authenticated || false;
        });
    }

    /**
     * Permet d'authentifier un utilisateur
     * @param user object User credentials
     */
    async login(user: any) {
        const url = environment.apiEndpoints.login;
        const appVersion = this.versionService.isMobileDevice()
            ? await this.appVersion.getVersionNumber()
            : '0.0.0i';
        return this.api
            .post(
                url,
                {
                    username: user._username,
                    password: user._password,
                    applicationCode: environment.applicationCode,
                    deviceUuid: this.device.uuid || 'unknown',
                    currentSvpeVersion: appVersion,
                },
                false,
                environment.svpeUrl
            )
            .then(data => {
                this.storage.set('user', data);
                return data;
            });
    }

    /**
     * Déconnexion de l'utilisateur connecté
     */
    logout() {
        this.autheticated = false;
        return this.storage.remove('user');
    }

    /**
     * check if there is a token and if the token is still valid
     * @param refresh true to check in the storage, false by default to return the value already calculated in the service
     */
    isAuthenticated(refresh = false): Promise<boolean | void> {
        if (!refresh) {
            return Promise.resolve(this.autheticated);
        }
        return this.storage.get('user').then(data => {
            if (data) {
                // console.log('user token', data.token);

                // check token is valid or not => check the bear ${token} pass or not
                const url = environment.apiEndpoints.token;
                return this.api.post(url, null, true, environment.svpeUrl).then((valid: boolean) => {
                    return valid;
                }).catch(err => {
                    if (err.status === 401) {
                        return false;
                    }
                    return true;        // no connection => arrive at homePage directly
                });
            }
            return false;
        });
    }

    /**
     * Update user last login date
     */
    updateLoginDate() {
        const route = environment.apiEndpoints.updateLoginDate;
        this.api.get(route, null, true, environment.svpeUrl);
    }
}
