import { Column, Entity, Index, JoinColumn, JoinTable, ManyToMany, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { Installation } from './Installation';
import { Level } from './Level';
import { RefChapterRubric } from './RefChapterRubric';
import { RefObservation } from './RefObservation';
import { RefTheme } from './RefTheme';
import { BaseEntity } from './BaseEntity';
import { Verification } from './Verification';
import { Building } from './Building';

@Entity('observation')
export class Observation extends BaseEntity {
  apiMapping = {
    id: 'extId',
    number: 'number',
    name: 'name',
    chapterShortName: 'chapterShortName',
    chapterLongName: 'chapterLongName',
    rubricShortName: 'rubricShortName',
    rubricLongName: 'rubricLongName',
    area: 'area',
    description: 'description',
    recommendations: 'recommendations',
    themeName: 'refThemeName',
    priority: 'priority',
    pictureName: 'pictureName',
    pictureData: {
      type: 'Photo',
      fieldName: 'pictureData'
    },
    nvReason: 'nvReason',    // Motivation de l'avis NV
    isNew: 'isNew',
    isChecked: 'isChecked',
    isDeleted: 'isDeleted',
    createdDate: 'createdDate',
    updatedDate: 'updatedDate',
    refObservationId: {
      fieldName: 'refObservation',
      type: 'RefObservation'
    },
    refChapterId: {
      fieldName: 'refChapter',
      type: 'RefChapterRubric'
    },
    refRubricId: {
      fieldName: 'refRubric',
      type: 'RefChapterRubric'
    },
    refThemeId: {
      fieldName: 'refTheme',
      type: 'RefTheme'
    },
    levels: {
      fieldName: 'levels',
      type: 'Level',
      map: false,
      mapType: 'many',
      mark: 'obs_ele'
    },
    installations: {
      fieldName: 'installations',
      type: 'Installation',
      map: false,
      mapType: 'many',
      mark: 'obs_ele'
    },
  };
  @PrimaryGeneratedColumn()
  id: number;

  @Column({ nullable: true })
  @Index()
  extId: number;

  @ManyToOne(type => Building)
  building: Building;

  @ManyToMany(type => Installation, i => i.observations)
  @JoinTable()
  installations: Installation[];

  @ManyToOne(type => Verification, verification => verification.observations)
  @JoinColumn()
  verification: Verification;

  @ManyToMany(type => Level, l => l.observations)
  @JoinTable()
  levels: Level[];

  @Column({ nullable: true })
  number: number;

  @Column({ nullable: true })
  name: string;

  @ManyToOne(type => RefChapterRubric)
  refChapter: RefChapterRubric;

  @Column({ nullable: true })
  chapterShortName: string;

  @Column({ nullable: true })
  chapterLongName: string;

  @ManyToOne(type => RefChapterRubric)
  refRubric: RefChapterRubric;

  @Column({ nullable: true })
  rubricShortName: string;

  @Column({ nullable: true })
  rubricLongName: string;

  @ManyToOne(type => RefObservation)
  refObservation: RefObservation;

  // @Column({ nullable: true })
  // localObservation: number;

  @Column({ nullable: true })
  area: string;      // local

  @Column({ nullable: true })
  description: string;

  @Column({ nullable: true })
  recommendations: string;

  @ManyToOne(type => RefTheme)
  refTheme: RefTheme;

  @Column({ nullable: true })
  refThemeName: string;

  @Column({ nullable: true })
  priority: number;

  @Column({ nullable: true })
  pictureName: string;

  @Column({ nullable: true, select: false })
  pictureData: string;

  @Column({ nullable: true })
  nvReason: string;    // Motivation de l'avis NV

  @Column({ nullable: true })
  isNew: boolean;

  @Column({ nullable: true })
  isChecked: boolean;

  @Column({ nullable: true })
  isDeleted: boolean;

  @Column({ nullable: true })
  createdDate: string;

  @Column({ nullable: true })
  updatedDate: string;
}
