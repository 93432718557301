import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { BaseEntity } from './BaseEntity';
import { Building } from './Building';
import { Installation } from './Installation';
import { Verification } from './Verification';

@Entity('annex')
export class Annex extends BaseEntity {
  apiMapping = {
    id: 'extId',
    title: 'title',
    description: 'description',
    fileName: 'fileName',
    fileData: {
      type: 'AnnexFile',
      fieldName: 'fileData'
    },
    isReportIncluded: 'isReportIncluded',
    buildingId: 'buildingId',
    installationId: 'installationId',
    verificationId: 'verificationId'
  };

  @PrimaryGeneratedColumn()
  id: number;

  @ManyToOne(() => Verification, verification => verification.annexes)
  @JoinColumn()
  verification: Verification;

  @Column({ nullable: true })
  @Index()
  extId: number;

  @Column({ nullable: true })
  title: string;

  @Column({ nullable: true })
  description: string;

  @Column({ nullable: true })
  fileName: string;

  @Column({ nullable: true, select: false })
  fileData: string;

  @Column({ nullable: true })
  isReportIncluded: boolean;

  @Column({ nullable: true })
  buildingId: number;

  @Column({ nullable: true })
  installationId: number;

  building: Building;
  installation: Installation;
}
