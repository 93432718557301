import { Column, Entity, ManyToMany, PrimaryGeneratedColumn } from 'typeorm';
import { BaseEntity } from './BaseEntity';
import { RefChapterRubric } from './RefChapterRubric';

@Entity('ref_mission')
export class RefMission extends BaseEntity {
  apiMapping = {
      id: 'id',
      name: 'name',
      hasMandatoryRecommendation: 'hasMandatoryRecommendation',
      isSo: 'isSo'
  };

  @PrimaryGeneratedColumn()
  id: number;

  @Column()
  name: string;

  @Column()
  hasMandatoryRecommendation: boolean;

  @Column()
  isSo: boolean;

  @Column({ nullable: true })
  isDeleted: boolean;

  @ManyToMany(type => RefChapterRubric, ref => ref.refMissions)
  refChapterRubrics: RefChapterRubric[];
}
