import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';
import { BaseEntity } from './BaseEntity';

@Entity('ref_ranking_origin')
export class RefRankingOrigin extends BaseEntity {
  apiMapping = {
      id: 'id',
      name: 'name',
  };

  @PrimaryGeneratedColumn()
  id: number;

  @Column()
  name: string;

  @Column({ nullable: true })
  isDeleted: boolean;
}
