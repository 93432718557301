import * as moment from 'moment';
import { getRepository, In, Repository } from 'typeorm';

export abstract class BaseEntity {
    protected apiMapping: any;
    protected id;
    protected verification;
    protected building;

    /**
     * Fonction permettant de mapper les données de l'api vers entitées TypeORM
     * @param data Object Les données venant de l'api
     * @param update boolean Flag permettant de faire la difference entre une création d'entité ou un mis à jour (Création par défaut)
     */
    async fromApi(data: any, update = false) {
        let fieldName;
        let type;
        let map;
        let mapType;

        for (const field in this.apiMapping) {
            if (typeof this.apiMapping[field] === 'string') {
                if (update && this.apiMapping[field] === 'id') {
                    continue;
                }
                this[this.apiMapping[field]] =
                    field === 'id' || field === 'customerClassification'
                        ? +data[field]
                        : data[field];
            } else if (typeof this.apiMapping[field] === 'object') {
                fieldName = this.apiMapping[field]['fieldName'];
                type = this.apiMapping[field]['type'];
                map = this.apiMapping[field]['map'];
                mapType = this.apiMapping[field]['mapType'];
                if (data[field] !== undefined && data[field] !== null) {
                    // console.log('debug - top level', data[field]);
                    if (this.apiMapping[field]['type'] === 'Date') {
                        this[fieldName] = +moment(data[field]).format('X');
                        continue;
                    }
                    if (
                        this.apiMapping[field]['type'] === 'Photo' ||
                        this.apiMapping[field]['type'] === 'AnnexFile'
                    ) {
                        console.time(type);
                        const binaryString = atob(data[field]);
                        const bytes = new Uint8Array(binaryString.length);
                        for (let i = 0; i < binaryString.length; i++) {
                            bytes[i] = binaryString.charCodeAt(i);
                        }
                        this[fieldName] =
                            '{"type": "Buffer", "data": [' + bytes + ']}';
                        console.timeEnd(type);
                        continue;
                    }
                    // On controle si on doit mapper l'object recursivement
                    if (map) {
                        let linkedData = this.getRelationsData(type);
                        console.log('debug - linkedData', linkedData);
                        const repo = getRepository(type);
                        if (repo === null || repo === undefined) {
                            console.warn('repo not found: ' + type);
                            continue;
                        }
                        // tslint:disable-next-line:prefer-conditional-expression
                        if (mapType === 'one') {
                            // console.time(type);
                            if (type === 'TechnicalFile') {
                                linkedData = null;
                            }
                            let element: any = repo.create(linkedData);
                            const obj = data[field];
                            await element.fromApi(obj);
                            element = await repo.save(element, {
                                transaction: false,
                            });
                            this[fieldName] = element;
                            // console.timeEnd(type);
                            console.log(
                                'debug - mapType one',
                                this,
                                fieldName,
                                element
                            );
                        } else {
                            // console.time(type);
                            // await Promise.all(data[field].map(async (elemData) => {
                            const elements = [];
                            for (let i = 0; i < data[field].length; i++) {
                                const elemData = data[field][i];
                                // console.time('create ' + type);
                                let elementObject: any =
                                    repo.create(linkedData);
                                // console.timeEnd('create ' + type);
                                // console.log('lot2 debug - elementObject before', elementObject);
                                if (elemData.id) {
                                    // console.time('exists ' + type);
                                    const old: any = await repo.findOne({
                                        extId: elemData.id,
                                    });
                                    if (old) {
                                        elementObject.id = old.id;
                                    }
                                    // console.timeEnd('exists ' + type);
                                } else if (elemData.refInstallationId) {
                                    // console.time('refInstall ' + type);
                                    const old: any = await repo.findOne({
                                        id: elemData.refInstallationId,
                                    });
                                    if (old) {
                                        elementObject = old;
                                    }
                                    // console.timeEnd('refInstall ' + type);
                                }

                                // console.time('save' + type);
                                elementObject = await repo.save(elementObject, {
                                    transaction: false,
                                });
                                // console.timeEnd('save' + type);
                                // *
                                // console.log('debug - quke, dataApi, linkedData', elementObject, elemData, linkedData);
                                // console.time('fromAPI' + type);
                                await elementObject.fromApi(elemData, true);
                                // console.timeEnd('fromAPI' + type);
                                // console.log(
                                //     'debug - elementObject *type*',
                                //     elementObject,
                                //     elementObject.constructor.name,
                                //     elementObject.constructor.name.toString()
                                // );
                                if (
                                    type === 'TechnicalFile' ||
                                    type === 'RefInstallation'
                                ) {
                                    elementObject.installations =
                                        linkedData.installations;
                                    // console.log('***test import TF/refIns - ', elementObject);
                                }

                                if (type === 'Observation') {
                                    if (elementObject.levels) {
                                        let cleanedLevels = [];
                                        elementObject.levels.forEach((el) => {
                                            if (el !== undefined) {
                                                cleanedLevels.push(el);
                                            }
                                        });

                                        elementObject.levels = cleanedLevels;
                                    }

                                    if (elementObject.installations) {
                                        let cleanedInstallations = [];
                                        elementObject.installations.forEach(
                                            (el) => {
                                                if (el !== undefined) {
                                                    cleanedInstallations.push(
                                                        el
                                                    );
                                                }
                                            }
                                        );

                                        elementObject.installations =
                                            cleanedInstallations;
                                    }
                                }

                                elements.push(elementObject);
                                // return Promise.resolve(eoL);
                                // }));
                            }

                            if (elements.length) {
                                const repoL = getRepository(
                                    elements[0].constructor.name
                                );
                                // console.time('Lsave' + type);
                                await repoL.save(elements, {
                                    transaction: false,
                                });
                                // console.timeEnd('Lsave' + type);
                            }
                            // console.timeEnd(type);
                        }
                    } else {
                        if (typeof data[field] !== 'object') {
                            this[fieldName] = {
                                id: +data[field],
                            };
                        } else {
                            if (this.apiMapping[field]['mark'] === 'obs_ele') {
                                this[fieldName] = [];
                                // console.time('obs');
                                for (const elem of data[field]) {
                                    const obj =
                                        fieldName === 'installations'
                                            ? 'Installation'
                                            : 'Level';
                                    const repo = getRepository(obj);
                                    const res = await repo.findOne({
                                        extId: +elem,
                                    });
                                    this[fieldName].push(res);
                                }
                                // console.timeEnd('obs');
                                // console.log('[extId]one to many fromApi debug - fieldName', this, fieldName, this[fieldName]);
                            } else if (fieldName === 'refInstallations') {
                                this[fieldName] = data[field].map((elem) => ({
                                    id: +elem.refInstallationId,
                                }));
                                // console.log('[fix id]many to many ins-refIns', this, fieldName, this[fieldName]);
                            } else if (fieldName === 'refRankingDisposition') {
                                this[fieldName] = data[field].map((elem) => ({
                                    id: +elem.id,
                                }));
                                // console.log('[fix id]many to many r.rankingDisposition', this, fieldName, data[field], this[fieldName]);
                            } else {
                                this[fieldName] = data[field].map((elem) => ({
                                    id: +elem,
                                }));
                                // console.log('[fix id]one to many fromApi debug - fieldName', this, fieldName, this[fieldName]);
                            }
                        }
                    }
                } else {
                    this[this.apiMapping[field]['fieldName']] = null;
                }
            }
        }
        return this;
    }

    async getOldsByIds(repo: Repository<any>, ids) {
        if (ids.length === 0) {
            return {};
        }

        const oldElements = await repo.find({
            where: {
                extId: In(ids),
            },
        });

        // console.log('oldElement', oldElements);

        const response = {};

        oldElements.forEach((element) => {
            response[element.extId] = element;
        });

        return response;
    }

    /**
     * Permet de tranformer les données en BDD au format attendu par l'API
     */
    toApi() {
        const object: any = {};

        for (const field in this.apiMapping) {
            if (typeof this.apiMapping[field] === 'string') {
                object[field] = this[this.apiMapping[field]];
            } else if (typeof this.apiMapping[field] === 'object') {
                const fieldName = this.apiMapping[field]['fieldName'];
                const type = this.apiMapping[field]['type'];
                const map =
                    this.apiMapping[field]['mapToApi'] ||
                    this.apiMapping[field]['map'];
                const mapType = this.apiMapping[field]['mapType'];
                // tslint:disable-next-line:prefer-conditional-expression
                if (this[fieldName] !== undefined && this[fieldName] != null) {
                    // tslint:disable-next-line:prefer-conditional-expression
                    if (type === 'Date') {
                        object[field] = moment
                            .unix(this[fieldName])
                            .toISOString();
                        continue;
                    }
                    try {
                        if (type === 'Photo') {
                            object[field] = JSON.parse(this[fieldName]).data;
                            continue;
                        }
                    } catch {
                        console.error('catch json parse exception');
                    }
                    if (map) {
                        // tslint:disable-next-line:prefer-conditional-expression
                        if (mapType === 'one') {
                            object[field] = this[fieldName].toApi();
                        } else {
                            if (
                                (type === 'Installation' || type === 'Level') &&
                                this.apiMapping[field]['mark'] === 'obs_ele'
                            ) {
                                console.log(object[field]);
                                object[field] = this[fieldName].map(
                                    (elem) => elem.id
                                );
                            } else {
                                object[field] = this[fieldName].map((elem) =>
                                    elem.toApi()
                                );
                            }
                        }
                    } else {
                        object[field] =
                            this[fieldName].id ||
                            this[fieldName].map((elem) => elem.id);
                    }
                } else {
                    object[field] = null;
                }
            }
        }

        return object;
    }

    /**
     * Permet de recuperer les données des relations parent pour chaque type à enregistrer
     * @param type  Le type de donnes attendu
     */
    protected getRelationsData(type: string) {
        switch (type) {
            case 'Appointment':
            case 'PreliminaryInterview':
            case 'Ranking':
                // console.log(type, this);
                return {
                    id: +this.id,
                    verification: {
                        id: +this.id,
                    },
                };
            case 'Building':
                return {
                    verification: {
                        id: +this.id,
                    },
                };
            case 'Level':
                // console.log(type, this);
                return {
                    building: {
                        id: this.id,
                    },
                    verification: {
                        id: +this.verification.id,
                    },
                };
            case 'Installation':
            case 'Observation':
                return {
                    building: {
                        id: +this.id,
                    },
                    verification: {
                        id: +this.verification.id,
                    },
                };
            case 'TechnicalFile':
                return {
                    installations: [
                        {
                            id: +this.id,
                        },
                    ],
                };
            case 'RefInstallation':
                return {
                    installations: [
                        {
                            id: +this.id,
                        },
                    ],
                };
            case 'Note':
                return {
                    installation: {
                        id: +this.id,
                    },
                    building: {
                        id: +this.building.id,
                    },
                    verification: {
                        id: +this.verification.id,
                    },
                };
            case 'So':
                return {
                    installation: this.id,
                };
        }
        return null;
    }
}
