import { Column, Entity, JoinColumn, OneToOne, PrimaryGeneratedColumn } from 'typeorm';
import { Verification } from './Verification';
import { BaseEntity } from './BaseEntity';

@Entity('appointment')
export class Appointment extends BaseEntity {
  apiMapping = {
    interventionRef: 'interventionRef',
    employeeNumber: 'employeeNumber',
    employeeFullName: 'employeeFullName',
    startDate: 'startDate',
    endDate: 'endDate',
  };

  @PrimaryGeneratedColumn()
  id: number;

  @OneToOne(() => Verification, v => v.appointment)
  @JoinColumn()
  verification: Verification;

  @Column({ nullable: true })
  interventionRef: string;

  @Column({ nullable: true })
  startDate: string;

  @Column({ nullable: true })
  endDate: string;

  @Column({ nullable: true })
  employeeNumber: string;

  @Column({ nullable: true })
  employeeFullName: string;
}
