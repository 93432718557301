import { Column, Entity, OneToMany, PrimaryGeneratedColumn } from 'typeorm';
import { BaseEntity } from './BaseEntity';
import { Ranking } from './Ranking';

@Entity('ref_ranking_establishment')
export class RefRankingEstablishment extends BaseEntity {
  apiMapping = {
      id: 'id',
      name: 'name',
  };

  @PrimaryGeneratedColumn()
  id: number;

  @Column()
  name: string;

  @OneToMany(type => Ranking, vr => vr.refRankingEstablishment)
  verificationRanking: Ranking[];

  @Column({ nullable: true })
  isDeleted: boolean;
}
