import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { createConnection, getConnection, getRepository } from 'typeorm';
import { Config } from '../config';
import { Check } from '../entities/Check';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  connection: any;
  dbIsReady: BehaviorSubject<boolean>;
  constructor(private versionService: VersionService) {
    this.dbIsReady = new BehaviorSubject(false);
  }

  async initConnection() {
    const connectionParams = this.versionService.isMobileDevice() ? Config.databaseConfig.cordova : Config.databaseConfig.browser;
    // this.connection = await createConnection(connectionParams);

    // this.dbIsReady.next(true);
    // console.log('Start creating connection', connectionParams);

    return createConnection(connectionParams).then(connection => {
      this.connection = connection;
      this.dbIsReady.next(true);

    }).catch(err => { console.log('err opening db', err) });
  }

  exportDatabase() {
    // console.log(this.connection);
    const buffer = this.connection.driver.databaseConnection.export();

    const blob = new Blob([buffer]);
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    const fileName = 'export_' + moment().format('YYYYMMDD_HHmmss') + '.db';
    a.download = fileName;
    a.onclick = () => {
      setTimeout(() => {
        window.URL.revokeObjectURL(a.href);
      }, 1500);
    };
    a.click();
  }

  vacuum() {
    return getConnection().query('VACUUM;');
  }

  async setSynchroState(state: 'ok' | 'ko') {
    const repo = getRepository(Check);
    let check = await repo.findOne({ name: 'synchro_state' });
    if (!check) {
      check = new Check();
      check.name = 'synchro_state';
    }
    check.value = state;

    repo.save(check, { transaction: false });
  }

  async synchronizeCheckIsOK() {
    const repo = getRepository(Check);
    const check = await repo.findOne({ name: 'synchro_state' });
    // console.log(check);
    return check.value === 'ok' || false;
  }
}
