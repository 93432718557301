import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';
import { BaseEntity } from './BaseEntity';

@Entity('ref_alert_typology')
export class RefAlertTypology extends BaseEntity {        // Typologie de l'installation Alerte

    apiMapping = {
        id: 'id',
        name: 'name',
    };

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    name: string;

    @Column({ nullable: true })
    isDeleted: boolean;
}
