import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // { path: '', redirectTo: 'loading', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'loading', loadChildren: () => import('./loading/loading.module').then(m => m.LoadingPageModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  { path: 'import-result', loadChildren: () => import('./import/result/result.module').then(m => m.ResultPageModule) },
  { path: 'import-search', loadChildren: () => import('./import/search/search.module').then(m => m.SearchPageModule) },
  { path: 'verification-create', loadChildren: () => import('./verification/create/create.module').then(m => m.CreatePageModule) },
  { path: 'verification-duplicate', loadChildren: () => import('./verification/duplicate/duplicate.module').then(m => m.DuplicatePageModule) },
  { path: 'verification/:id', loadChildren: () => import('./verification/verification.module').then(m => m.VerificationPageModule) },
  { path: 'preliminary-interview/:id', loadChildren: () => import('./verification/preliminary-interview/preliminary-interview.module').then(m => m.PreliminaryInterviewPageModule) },
  { path: 'preliminary-interview/:id/edit', loadChildren: () => import('./verification/preliminary-interview/preliminary-interview.module').then(m => m.PreliminaryInterviewPageModule) },
  { path: 'validate-visit/:id', loadChildren: () => import('./verification/validate-visit/validate-visit.module').then(m => m.ValidateVisitPageModule) },
  { path: 'verification/:id/building', loadChildren: () => import('./verification/building/building.module').then(m => m.BuildingPageModule) },
  { path: 'verification/:id/building/:buildingId/level', loadChildren: () => import('./verification/level/level.module').then(m => m.LevelPageModule) },
  { path: 'classment/:id', loadChildren: () => import('./classment/classment.module').then(m => m.ClassmentPageModule) },
  { path: 'classment-options/:id', loadChildren: () => import('./classment/classment-options/classment-options.module').then(m => m.ClassmentOptionsPageModule) },
  { path: 'classment-detail/:id', loadChildren: () => import('./classment/classment-detail/classment-detail.module').then(m => m.ClassmentDetailPageModule) },
  { path: 'export', loadChildren: () => import('./export/export.module').then(m => m.ExportPageModule) },
  { path: 'verification/:id/note', loadChildren: () => import('./verification/note/note.module').then(m => m.NotePageModule) },
  { path: 'verification/:id/note/add', loadChildren: () => import('./verification/note/add/add.module').then(m => m.AddPageModule) },
  { path: 'verification/:id/bat/:batId/ins/:insId/note/add', loadChildren: () => import('./verification/note/add/add.module').then(m => m.AddPageModule) },
  { path: 'verification/:id/note/:noteId/edit', loadChildren: () => import('./verification/note/edit/edit.module').then(m => m.EditPageModule) },
  { path: 'observation/:id', loadChildren: () => import('./observation/observation.module').then(m => m.ObservationPageModule) },
  { path: 'bat/:batId/ins/:insId/observations', loadChildren: () => import('./observation/observation.module').then(m => m.ObservationPageModule) },
  { path: 'installation/:batId', loadChildren: () => import('./installation/installation.module').then(m => m.InstallationPageModule) },
  { path: 'installation/:batId/examen-step1/:installationId', loadChildren: () => import('./installation/examen-step1/examen-step1.module').then(m => m.ExamenStep1PageModule) },
  { path: 'installation/:batId/examen-step1/:installationId/examen-step2/:chapterId', loadChildren: () => import('./installation/examen-step2/examen-step2.module').then(m => m.ExamenStep2PageModule) },
  { path: 'installation/:batId/examen-step1/:installationId/examen-step2/:chapterId/examen-step3/:rubriqueId', loadChildren: () => import('./installation/examen-step3/examen-step3.module').then(m => m.ExamenStep3PageModule) },
  { path: 'installation/:batId/examen-step1/:installationId/examen-step2/:chapterId/examen-step3/:rubriqueId/create-observation', loadChildren: () => import('./installation/create-observation/create-observation.module').then(m => m.CreateObservationPageModule) },
  { path: 'installation/:batId/examen-step1/:installationId/examen-step2/:chapterId/examen-step3/:rubriqueId/create-observation/:obsId', loadChildren: () => import('./installation/create-observation/create-observation.module').then(m => m.CreateObservationPageModule) },
  { path: 'installation/:batId/examen-step1/:installationId/examen-step2/:chapterId/examen-step3/:rubriqueId/create-observation/:obsId/nv', loadChildren: () => import('./installation/create-observation/create-observation.module').then(m => m.CreateObservationPageModule) },
  { path: 'installation/:batId/examen-step1/:installationId/examen-step2/:chapterId/examen-step3/:rubriqueId/create-observation/ref/:refObsId', loadChildren: () => import('./installation/create-observation/create-observation.module').then(m => m.CreateObservationPageModule) },
  { path: 'installation/:batId/examen-step1/:installationId/examen-step2/:chapterId/examen-step3/:rubriqueId/create-observation/ref/:refObsId/nv', loadChildren: () => import('./installation/create-observation/create-observation.module').then(m => m.CreateObservationPageModule) },
  { path: 'installation/:batId/examen-step1/:installationId/examen-step2/:chapterId/examen-step3/:rubriqueId/nv', loadChildren: () => import('./installation/create-observation/create-observation.module').then(m => m.CreateObservationPageModule) },
  { path: 'installation/:batId/examen-step1/:installationId/examen-step2/:chapterId/examen-step3/:rubriqueId/nv/level/:levelId', loadChildren: () => import('./installation/create-observation/create-observation.module').then(m => m.CreateObservationPageModule) },
  { path: 'installation/:batId/examen-step1/:installationId/examen-step2/:chapterId/examen-step3/:rubriqueId/refObs/:refObsId/nv/level/:levelId', loadChildren: () => import('./installation/create-observation/create-observation.module').then(m => m.CreateObservationPageModule) },
  { path: 'building/:buildingId/create-installation/:insFamilyId/:insId', loadChildren: () => import('./installation/create-installation/create-installation.module').then(m => m.CreateInstallationPageModule) },
  { path: 'verification/:id/annex', loadChildren: () => import('./verification/annex/annex.module').then(m => m.AnnexPageModule) },
  { path: 'verification/:id/annex/:annexId/details', loadChildren: () => import('./verification/annex/details/details.module').then(m => m.DetailsPageModule) },
  { path: 'verification/:id/annex/:annexId/edit', loadChildren: () => import('./verification/annex/edit/edit.module').then(m => m.EditPageModule) },
  { path: 'verification/:id/annex/create', loadChildren: () => import('./verification/annex/edit/edit.module').then(m => m.EditPageModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
