import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Network } from '@ionic-native/network/ngx';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { ImageCropperModule } from 'ngx-image-cropper';
import { environment } from '../environments/environment';
import { LogService } from './service/log.service';
import { StorageService } from './service/storage.service';
import { ModalComponent } from './shared/modal/modal.component';
import { ModalComponentModule } from './shared/modal/modal.module';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [ModalComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicStorageModule.forRoot({
            driverOrder: [
                CordovaSQLiteDriver._driver,
                Drivers.IndexedDB,
                Drivers.LocalStorage,
            ],
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        IonicModule.forRoot(),
        AppRoutingModule,
        ReactiveFormsModule,
        ModalComponentModule,
        ImageCropperModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.offline,
        }),
    ],
    providers: [
        Device,
        AppVersion,
        StatusBar,
        SplashScreen,
        ScreenOrientation,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        Network,
        File,
        Crop,
        UniqueDeviceID,
        CallNumber,
        Camera,
        LogService,
        FileChooser,
        FileOpener,
        StorageService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
