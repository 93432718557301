import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';
import { BaseEntity } from './BaseEntity';

@Entity('ref_ssi_typology')
export class RefSsiTypology extends BaseEntity {    //  Typologie de l'installation SSI
  apiMapping = {
      id: 'id',
      name: 'name',
  };

  @PrimaryGeneratedColumn()
  id: number;

  @Column()
  name: string;

  @Column({ nullable: true })
  isDeleted: boolean;
}
