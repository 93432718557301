import { Component } from '@angular/core';

import { SwUpdate } from '@angular/service-worker';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DbService } from './service/db.service';
import { LoginService } from './service/login.service';
import { StorageService } from './service/storage.service';
import { VersionService } from './service/version.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent {
    showWaitingScreen = false;

    constructor(
        private platform: Platform,
        private statusBar: StatusBar,
        private translate: TranslateService,
        private versionService: VersionService,
        private loginService: LoginService,
        private dbService: DbService,
        private storage: StorageService,
        private nav: NavController,
        private sw: SwUpdate // private screenOrientation: ScreenOrientation,
    ) {
        this.showWaitingScreen = true;
        translate.setDefaultLang('fr');
        this.translate.use('fr');
        this.sw.available.subscribe((event) => {
            this.sw.activateUpdate().then(() => {
                document.location.reload();
            });
        });
        this.sw
            .checkForUpdate()
            .then(() => {
                console.debug('service worker checked');
            })
            .finally(() => {
                this.initializeApp();
                this.showWaitingScreen = false;
            });
    }

    async initializeApp() {
        this.platform.ready().then(async () => {
            // Bloque en mode portrait
            // if (this.versionService.isMobileDevice()) {
            //   this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
            // }

            await this.dbService.initConnection();

            const version = await this.versionService.isValidVersion(true);
            const auth = await this.loginService.isAuthenticated(true);
            if (auth) {
                this.loginService.updateLoginDate();
            }

            const navigation = this.getNavigationPath(auth, version);

            navigation.then(() => {
                return this.initEnvironnement().then((_) => {
                    this.statusBar.styleDefault();
                });
            });
        });
    }

    async initEnvironnement() {
        await this.storage
            .get('lang')
            .then(async (data) => {
                if (data) {
                    return this.translate.use(data);
                } else {
                    return this.translate
                        .use('fr')
                        .toPromise()
                        .then(() => {
                            return this.storage.set('lang', 'fr');
                        });
                }
            })
            .catch((err) => {
                console.error('err init env', err);
            });
    }

    private getNavigationPath(auth, version) {
        let navigation;

        if (auth && version) {
            navigation = this.nav.navigateRoot('/home');
        } else if (version) {
            navigation = this.nav.navigateRoot('/login');
        } else {
            navigation = this.nav.navigateRoot('/loading');
        }

        return navigation;
    }
}
