import { Verification } from './entities/Verification';
import { RefDebriefingStatus } from './entities/RefDebriefingStatus';
import { RefSupervision } from './entities/RefSupervision';
import { RefWithAttendant } from './entities/RefWithAttendant';
import { PreliminaryInterview } from './entities/PreliminaryInerview';
import { So } from './entities/So';
import { RefRankingDisposition } from './entities/RefRankingDisposition';
import { RefRankingEstablishment } from './entities/RefRankingEstablishment';
import { RefRankingHouseFamily } from './entities/RefRankingHouseFamily';
import { RefRankingHouseHome } from './entities/RefRankingHouseHome';
import { RefRankingOrigin } from './entities/RefRankingOrigin';
import { RefRankingType } from './entities/RefRankingType';
import { Building } from './entities/Building';
import { Level } from './entities/Level';
import { Installation } from './entities/Installation';
import { TechnicalFile } from './entities/TechnicalFile';
import { Note } from './entities/Note';
import { Observation } from './entities/Observation';
import { RefVerificationStatus } from './entities/RefVerificationStatus';
import { Appointment } from './entities/Appointment';
import { Ranking } from './entities/Ranking';
import { RefAlertTypology } from './entities/RefAlertTypology';
import { RefMission } from './entities/RefMission';
import { RefInstallationFamily } from './entities/RefInstallationFamily';
import { RefChapterRubric } from './entities/RefChapterRubric';
import { RefTheme } from './entities/RefTheme';
import { RefObservation } from './entities/RefObservation';
import { RefRemoteControlOrigin } from './entities/RefRemoteControlOrigin';
import { RefSsiTypology } from './entities/RefSsiTypology';
import { RefSurveillanceTypology } from './entities/RefSurveillanceTypology';
import { RefSwitchType } from './entities/RefSwitchType';
import { RefVerificationLimit } from './entities/RefVerificationLimit';
import { RefMissionArea } from './entities/RefMissionArea';
import { RefInstallationCategory } from './entities/RefInstallationCategory';
import { RefInstallation } from './entities/RefInstallation';
import { RefAgence } from './entities/RefAgence';
import { Nomenclature } from './entities/Nomenclature';
import { Check } from './entities/Check';
import { Annex } from './entities/Annex';

export class Config {

  // Liste des entites
  private static entities = [
    Nomenclature,
    RefAgence,
    RefVerificationStatus,
    RefRankingDisposition,
    RefRankingEstablishment,
    RefRankingHouseFamily,
    RefRankingHouseHome,
    RefRankingOrigin,
    RefRankingType,
    RefSupervision,
    RefWithAttendant,
    RefRemoteControlOrigin,
    RefSsiTypology,
    RefSurveillanceTypology,
    RefSwitchType,
    RefVerificationLimit,
    RefMissionArea,
    RefDebriefingStatus,
    RefAlertTypology,
    RefInstallationCategory,
    RefInstallation,
    RefMission,
    RefChapterRubric,
    RefTheme,
    RefObservation,
    RefInstallationFamily,
    Verification,
    PreliminaryInterview,
    TechnicalFile,
    So,
    Building,
    Level,
    Installation,
    Note,
    Observation,
    Appointment,
    Ranking,
    Check,
    Annex
  ];

  private static browserConfig: any = {
    name: 'default',
    type: 'sqljs',
    autoSave: true,
    location: 'rapsoMS.db',
    logging: ['error'],
    useLocalForage: true,
    synchronize: true,
    entities: Config.entities,
  };

  private static cordovaConfig: any = {
    name: 'default',
    type: 'cordova',
    database: 'rapsoMS.db',
    location: 'default',
    logging: ['error', 'query', 'schema'],
    synchronize: true,
    migrationsTransactionMode: 'none',
    entities: Config.entities,
  };

  static databaseConfig = {
    browser: Config.browserConfig,
    cordova: Config.cordovaConfig,
  };
}
