import { Observation } from './Observation';
import { Note } from './Note';
import { Annex } from './Annex';
import { RefVerificationStatus } from './RefVerificationStatus';
import { BaseEntity } from './BaseEntity';
import { RefDebriefingStatus } from './RefDebriefingStatus';
import { Column, Entity, Index, JoinColumn, ManyToOne, OneToMany, OneToOne, PrimaryGeneratedColumn } from 'typeorm';
import { Level } from './Level';
import { Building } from './Building';
import { Installation } from './Installation';
import { PreliminaryInterview } from './PreliminaryInerview';
import { Appointment } from './Appointment';
import { Ranking } from './Ranking';

@Entity('verification')
export class Verification extends BaseEntity {
    apiMapping = {
        // exid: 'id',
        id: 'extId',
        refVerificationStatusId: {
            type: 'RefVerificationStatus',
            fieldName: 'status'
        },
        buildings: {
            fieldName: 'buildings',
            type: 'Building',
            map: true,
            mapType: 'many'
        },
        isExtracted: 'isExtracted',
        extractedByFullName: 'extractedByFullName',
        chronoIdNumber: 'chronoIdNumber',
        canvasNumber: 'canvasNumber',
        businessRef: 'businessRef',
        businessLabel: 'businessLabel',
        customerName: 'customerName',
        customerAddress1: 'customerAddress1',
        customerAddress2: 'customerAddress2',
        customerAddress3: 'customerAddress3',
        customerClassification: 'nomenclatureId',
        customerCity: 'customerCity',
        customerPhoneNumber: 'customerContactPhone',
        customerEmail: 'customerContactEmail',
        customerZipCode: 'customerZipcode',
        customerContactFullName: 'customerContactName',
        establishmentAddress1: 'establishmentAddress1',
        establishmentAddress2: 'establishmentAddress2',
        establishmentAddress3: 'establishmentAddress3',
        establishmentZipcode: 'establishmentZipcode',
        establishmentCity: 'establishmentCity',
        establishmentContactFullName: 'establishmentContactName',
        establishmentPhoneNumber: 'establishmentContactPhone',
        establishmentEmail: 'establishmentContactEmail',
        customerSiteRef: 'customerSiteRef',
        agencyLabel: 'agencyLabel',
        agencyId: 'agencyId',
        establishmentName: 'establishmentName',
        areAllAreasChecked: 'areAllAreasChecked',
        checkLimits: 'checkLimits',
        refDebriefingStatusId: {
            type: 'RefDebriefingStatus',
            fieldName: 'refDebriefingStatus'
        },
        debriefingStatusPersonFullname: 'debriefingStatusPersonFullName',
        debriefingStatusPersonJobname: 'debriefingStatusPersonJob',
        hasSecurityBookStamp: 'hasSecurityBookStamp',
        needCustomerReview: 'needCustomerReview',
        validationDate: 'validationDate',
        reportNumber: 'reportNumber',
        interventionRef: 'interventionRef',
        // startDate: {
        //     type: 'Date',
        //     fieldName: 'startDate',
        // },
        startDate: 'startDate',
        // endDate: {
        //     type: 'Date',
        //     fieldName: 'endDate',
        // },
        endDate: 'endDate',
        employeeNumber: 'employeeNumber',
        employeeFullName: 'employeeFullName',
        isManualImport: 'isManualImport',
        // createdDate: {
        //     type: 'Date',
        //     fieldName: 'createdDate',
        // },
        createdDate: 'createdDate',
        // updatedDate: {
        //     type: 'Date',
        //     fieldName: 'updatedDate',
        // },
        updatedDate: 'updatedDate',
        appointment: {
            fieldName: 'appointment',
            type: 'Appointment',
            map: true,
            mapType: 'one'
        },
        ranking: {
            fieldName: 'ranking',
            type: 'Ranking',
            map: true,
            mapType: 'one'
        },
        preliminaryInterview: {
            fieldName: 'preliminaryInterview',
            type: 'PreliminaryInterview',
            map: true,
            mapType: 'one'
        },
        device: 'importDevice',
        replacedReportNumber: 'replacedReportNumber',
        replacedReportChronoIdNumber: 'replacedReportChronoIdNumber',
        annexes: {
            type: 'Annex',
            fieldName: 'annexes',
            map: true,
            mapType: 'many'
        },
        sitePictureName: 'sitePictureName',
        sitePictureData: {
        type: 'Photo',
        fieldName: 'sitePictureData'
        },
    };

    @PrimaryGeneratedColumn()
    id: number;

    @Column({ nullable: true })
    @Index()
    extId: number;

    @OneToMany(type => Note, note => note.verification)
    notes: Note[];

    @OneToMany(type => Annex, annex => annex.verification)
    annexes: Annex[];

    @OneToMany(type => Level, l => l.verification)
    levels: Level[];

    @OneToMany(type => Observation, observation => observation.verification)
    observations: Observation[];

    @OneToMany(type => Building, building => building.verification, { cascade: true })
    buildings: Building[];

    @OneToMany(type => Installation, installation => installation.verification)
    installations: Installation[];

    @ManyToOne(type => RefVerificationStatus)
    @JoinColumn()
    status: RefVerificationStatus;

    @OneToOne(type => PreliminaryInterview, p => p.verification)
    preliminaryInterview: PreliminaryInterview;

    @OneToOne(type => Appointment, a => a.verification, { cascade: true })
    appointment: Appointment;

    @OneToOne(type => Ranking, r => r.verification, { cascade: true })
    ranking: Ranking;

    @Column({ nullable: true })
    isExtracted: boolean;

    @Column({ nullable: true })
    extractedByFullName: string;

    @Column({ nullable: true })
    chronoIdNumber: string;

    @Column({ nullable: true })
    canvasNumber: string;

    @Column({ nullable: true })
    businessRef: string;

    @Column({ nullable: true })
    businessLabel: string;

    @Column({ nullable: true })
    nomenclatureId: number;

    @Column({ nullable: true })
    nomenclatureLabel: string;

    @Column({ nullable: true })
    customerName: string;

    @Column({ nullable: true })
    customerAddress1: string;

    @Column({ nullable: true })
    customerAddress2: string;

    @Column({ nullable: true })
    customerAddress3: string;

    @Column({ nullable: true })
    customerZipcode: string;

    @Column({ nullable: true })
    customerCity: string;

    @Column({ nullable: true })
    customerContactName: string;

    @Column({ nullable: true })
    customerContactPhone: string;

    @Column({ nullable: true })
    customerContactEmail: string;

    @Column({ nullable: true })
    customerClassification: string;

    @Column({ nullable: true })
    establishmentAddress1: string;

    @Column({ nullable: true })
    establishmentAddress2: string;

    @Column({ nullable: true })
    establishmentAddress3: string;

    @Column({ nullable: true })
    establishmentZipcode: string;

    @Column({ nullable: true })
    establishmentCity: string;

    @Column({ nullable: true })
    establishmentContactName: string;

    @Column({ nullable: true })
    establishmentContactPhone: string;

    @Column({ nullable: true })
    establishmentContactEmail: string;

    @Column({ nullable: true })
    customerSiteRef: string;

    @Column({ nullable: true })
    agencyLabel: string;

    @Column({ nullable: true })
    agencyId: string;

    @Column({ nullable: true })
    establishmentName: string;

    @Column({ nullable: true })
    areAllAreasChecked: boolean;

    @Column({ nullable: true })
    checkLimits: string;

    @ManyToOne(type => RefDebriefingStatus)
    refDebriefingStatus: RefDebriefingStatus;

    @Column({ nullable: true })
    debriefingStatusPersonFullName: string;

    @Column({ nullable: true })
    debriefingStatusPersonJob: string;

    @Column({ nullable: true })
    hasSecurityBookStamp: boolean;

    @Column({ nullable: true })
    needCustomerReview: boolean;

    @Column({ nullable: true })
    validationDate: string;

    @Column({ nullable: true })
    reportNumber: number;

    @Column({ nullable: true })
    interventionRef: string;

    @Column({ nullable: true })
    // startDate: number;
    startDate: string;

    @Column({ nullable: true })
    // endDate: number;
    endDate: string;

    @Column({ nullable: true })
    employeeNumber: string;

    @Column({ nullable: true })
    employeeFullName: string;

    @Column({ nullable: true })
    isManualImport: boolean;

    @Column({ nullable: true })
    createdDate: string;

    @Column({ nullable: true })
    updatedDate: string;

    @Column({ nullable: true })
    importDevice: string;

    @Column({ nullable: true })
    replacedReportNumber: string;

    @Column({ nullable: true })
    replacedReportChronoIdNumber: string;

    @Column({ nullable: true })
    sitePictureName: string;

    @Column({ nullable: true, select: false })
    sitePictureData: string;
}
