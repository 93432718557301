import { Column, Entity, Index, ManyToMany, PrimaryGeneratedColumn } from 'typeorm';
import { Installation } from './Installation';
import { BaseEntity } from './BaseEntity';

@Entity('technical_file')
export class TechnicalFile extends BaseEntity {
  apiMapping = {
    id: 'extId',
    title: 'title',
    reference: 'reference',
    version: 'version',
    numberOfPages: 'pages',
    author: 'author',
    refInstallationTechnicalFileTypeId: 'refInstallationTechnicalFileTypeId'
  };

  @PrimaryGeneratedColumn()
  id: number;

  @Column({ nullable: true })
  @Index()
  extId: number;

  @Column({ nullable: true })
  title: string;

  @Column({ nullable: true })
  reference: string;

  @Column({ nullable: true })
  version: string;

  @Column({ nullable: true })
  pages: number;

  @Column({ nullable: true })
  author: string;

  @Column({ nullable: true })
  refInstallationTechnicalFileTypeId: number;

  @ManyToMany(type => Installation, installation => installation.technicalFile)
  installations: Installation[];
}
