import { Column, Entity, ManyToMany, ManyToOne, PrimaryGeneratedColumn, Relation } from 'typeorm';
import { RefInstallationCategory } from './RefInstallationCategory';
import { BaseEntity } from './BaseEntity';
import { Installation } from './Installation';

@Entity('ref_installation')
export class RefInstallation extends BaseEntity {
  apiMapping = {
      refInstallationId: 'id',
      name: 'name',
      refInstallationCategoryId: {
        fieldName: 'refInstallationCategory',
        type: 'RefInstallationCategory'
      }
  };

  @PrimaryGeneratedColumn()
  id: number;

  @Column({ nullable: true })
  name: string;

  @ManyToOne(() => RefInstallationCategory)
  refInstallationCategory: RefInstallationCategory;

  @Column({ nullable: true })
  isDeleted: boolean;

  @ManyToMany(type => Installation, installation => installation.refInstallations)
  installations: Installation[];
}
