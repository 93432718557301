import { Column, Entity, ManyToOne, PrimaryGeneratedColumn, Relation } from 'typeorm';
import { RefMission } from './RefMission';
import { BaseEntity } from './BaseEntity';

@Entity('ref_theme')
export class RefTheme extends BaseEntity {
  apiMapping = {
      id: 'id',
      name: 'name',
      order: 'ordre',
      isDeleted: 'isDeleted',
      refChapterRubricId: {
        fieldName: 'refChapterRubric',
        type: 'RefChapterRubric',
      },
      refMissionId: {
        fieldName: 'refMission',
        type: 'RefMission',
      }
  };

  @PrimaryGeneratedColumn()
  id: number;

  @ManyToOne(() => RefMission)
  refMission: RefMission;

  // @ManyToOne(type => RefChapterRubric)
  // refChapterRubric: RefChapterRubric;

  @Column()
  name: string;

  @Column()
  ordre: number;

  @Column()
  isDeleted: boolean;
}
