import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public async set(key: string, value: any) {
    if (this._storage == null)
    {
      await this.init();
    }
    return this._storage?.set(key, value);
  }

  // get methods
  public async get(key:string){
    if (this._storage == null)
    {
      await this.init();
    }
    return this._storage?.get(key);
  }

  public async remove(key:string){
    if (this._storage == null)
    {
      await this.init();
    }
    return this._storage?.remove(key);
  }

  checkUsage() {
    return navigator.storage.estimate().then((data) => {
      return data.usage / data.quota;
    });
  }
}
