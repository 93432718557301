import {
    Column,
    Entity,
    JoinColumn,
    JoinTable,
    ManyToMany,
    ManyToOne,
    OneToOne,
    PrimaryGeneratedColumn,
} from 'typeorm';
import { Verification } from './Verification';
import { RefRankingEstablishment } from './RefRankingEstablishment';
import { RefRankingOrigin } from './RefRankingOrigin';
import { TechnicalFile } from './TechnicalFile';
import { RefRankingHouseFamily } from './RefRankingHouseFamily';
import { RefRankingHouseHome } from './RefRankingHouseHome';
import { BaseEntity } from './BaseEntity';
import { RefRankingDisposition } from './RefRankingDisposition';
import { RefRankingType } from './RefRankingType';

@Entity('ranking')
export class Ranking extends BaseEntity {
    apiMapping = {
        refRankingEstablishmentId: {
            type: 'RefRankingEstablishment',
            fieldName: 'refRankingEstablishment'
        },
        rankingCategory: 'rankingCategory',
        rankingOriginRefRankingOriginId: {
            type: 'RefRankingOrigin',
            fieldName: 'rankingOriginRefRankingOrigin',
        },
        rankingOriginTechnicalFile: {
            type: 'TechnicalFile',
            fieldName: 'rankingOriginTechnicalFile',
            map: true,
            mapType: 'one',
        },
        rankingOriginFullname: 'rankingOriginFullName',
        rankingOriginOther: 'rankingOriginOther',
        rankingGlobalNumberOperator: 'rankingGlobalNumberOperator',
        rankingGlobalNumberValue: 'rankingGlobalNumberValue',
        rankingPublicNumberOperator: 'rankingPublicNumberOperator',
        rankingPublicNumberValue: 'rankingPublicNumberValue',
        workforceOriginRefRankingOriginId: {
            type: 'RefRankingOrigin',
            fieldName: 'workforceOriginRefRankingOrigin'
        },
        workforceOriginTechnicalFile: {
            type: 'TechnicalFile',
            fieldName: 'workforceOriginTechnicalFile',
            map: true,
            mapType: 'one',
        },
        workforceOriginCivility: 'workforceOriginCivility',
        workforceOriginFullname: 'workforceOriginFullName',
        workforceOriginOther: 'workforceOriginOther',
        activityTopics: 'activityTopics',
        refRankingHouseFamilyId: {
            type: 'RefRankingHouseFamily',
            fieldName: 'refRankingHouseFamily'
        },
        refRankingHouseHomeId: {
            type: 'RefRankingHouseHome',
            fieldName: 'refRankingHouseHome'
        },
        isHouseErp: 'isHouseErp',
        houseErpDetails: 'houseErpDetails',
        hasCarPark: 'hasCarPark',
        refRankingTypes: {
            type: 'RefRankingType',
            fieldName: 'refRankingType'
          },
        refRankingDisposition: {
            type: 'RefRankingDisposition',
            fieldName: 'refRankingDisposition',
            map: false,
            mapToApi: true,
            mapType: 'many',
          },
    };

    @PrimaryGeneratedColumn()
    id: number;

    @OneToOne(type => Verification)
    @JoinColumn()
    verification: Verification;

    @ManyToMany(type => RefRankingDisposition)
    @JoinTable()
    refRankingDisposition: RefRankingDisposition[];

    @ManyToMany(type => RefRankingType)
    @JoinTable()
    refRankingType: RefRankingType[];

    @ManyToOne(type => RefRankingEstablishment)
    @JoinColumn()
    refRankingEstablishment: RefRankingEstablishment;

    @Column({ nullable: true })
    rankingCategory: string;

    @ManyToOne(type => RefRankingOrigin)
    @JoinColumn()
    rankingOriginRefRankingOrigin: RefRankingOrigin;

    @ManyToOne(type => TechnicalFile)
    @JoinColumn()
    rankingOriginTechnicalFile: TechnicalFile;

    @Column({ nullable: true })
    rankingOriginFullName: string;

    @Column({ nullable: true })
    rankingOriginOther: string;

    @Column({ nullable: true })
    rankingGlobalNumberOperator: string;

    @Column({ nullable: true })
    rankingGlobalNumberValue: number;

    @Column({ nullable: true })
    rankingPublicNumberOperator: string;

    @Column({ nullable: true })
    rankingPublicNumberValue: number;

    @ManyToOne(type => RefRankingOrigin)
    @JoinColumn()
    workforceOriginRefRankingOrigin: RefRankingOrigin;

    @ManyToOne(type => TechnicalFile)
    @JoinColumn()
    workforceOriginTechnicalFile: TechnicalFile;

    @Column({ nullable: true })
    workforceOriginCivility: string;

    @Column({ nullable: true })
    workforceOriginFullName: string;

    @Column({ nullable: true })
    workforceOriginOther: string;

    @Column({ nullable: true })
    activityTopics: string;

    @ManyToOne(type => RefRankingHouseFamily)
    @JoinColumn()
    refRankingHouseFamily: RefRankingHouseFamily;

    @ManyToOne(type => RefRankingHouseHome)
    @JoinColumn()
    refRankingHouseHome: RefRankingHouseHome;

    @Column({ nullable: true })
    isHouseErp: boolean;

    @Column({ nullable: true })
    houseErpDetails: string;

    @Column({ nullable: true })
    hasCarPark: boolean;
}
