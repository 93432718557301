import { RefMissionArea } from './RefMissionArea';
import { Column, Entity, In, Index, JoinColumn, JoinTable, ManyToMany, ManyToOne, OneToMany, PrimaryGeneratedColumn, getRepository } from 'typeorm';
import { RefInstallation } from './RefInstallation';
import { RefInstallationFamily } from './RefInstallationFamily';
import { RefAlertTypology } from './RefAlertTypology';
import { RefVerificationLimit } from './RefVerificationLimit';
import { RefRemoteControlOrigin } from './RefRemoteControlOrigin';
import { RefSwitchType } from './RefSwitchType';
import { Building } from './Building';
import { RefSurveillanceTypology } from './RefSurveillanceTypology';
import { TechnicalFile } from './TechnicalFile';
import { RefSsiTypology } from './RefSsiTypology';
import { BaseEntity } from './BaseEntity';
import { Verification } from './Verification';
import { Note } from './Note';
import { Observation } from './Observation';
import { Utils } from '../service/utils.service';
import { So } from './So';

@Entity('installation')
export class Installation extends BaseEntity {
  apiMapping = {
    id: 'extId',
    localId: 'id',
    refInstallationTypologyAlertId: {
      fieldName: 'refAlertTypology',
      type: 'RefAlertTypology',
    },
    installationTypologyOther: 'installationTypologyOther',
    refInstallationFamilyId: {
      fieldName: 'refInstallationFamily',
      type: 'refInstallationFamily'
    },
    familyName: 'familyName',
    area: 'area',
    hasConformityDocuments: 'hasConformityDocuments',
    presenceColonneFix: 'presenceColonneFix',
    conformityDocumentsShownDate: 'conformityDocumentsShownDate',
    reportedModificationsOnConformity: 'reportedModificationsOnConformity',
    featureAndConceptionExemption: 'featureAndConceptionExemption',
    refInstallationVerificationLimitId: {
      fieldName: 'refVerificationLimit',
      type: 'RefVerificationLimit'
    },
    commisioningYear: 'commisioningYear',
    hasLid: 'hasLid',
    otherDocumentsPresented: 'otherDocumentsPresented',
    otherInformation: 'otherInformation',
    zaNumber: 'zaNumber',
    zdaNumber: 'zdaNumber',
    zdmNumber: 'zdmNumber',
    delay: 'delay',
    refInstallationRemoteControlOriginId: {
      fieldName: 'refRemoteControlOrigin',
      type: 'RefRemoteControlOrigin'
    },
    productCharacteristics: 'productCharacteristics',
    fogWaterTypology: 'fogWaterTypology',
    refInstallationSwitchTypeId: {
      fieldName: 'refSwitchType',
      type: 'RefSwitchType'
    },
    rechargeDeviceCharacteristics: 'rechargeDeviceCharacteristics',
    powerSourceCharacteristics: 'powerSourceCharacteristics',
    refInstallationMissionAreaId: {
      fieldName: 'refMissionArea',
      type: 'RefMissionArea'
    },
    refInstallationTypologySurveillanceId: {
      fieldName: 'refSurveillanceTypology',
      type: 'RefSurveillanceTypology'
    },
    deciHasHydrants: 'deciHasHydrants',
    deciHasFixedColumns: 'deciHasFixedColumns',
    deciHasPea: 'deciHasPea',
    deciHasPen: 'deciHasPen',
    fixedColumnsCommisioningYear: 'fixedColumnsCommisioningYear',
    fixedColumnTechnicalFileId: {
      fieldName: 'fixedColumnTechnicalFile',
      type: 'TechnicalFile'
    },
    hydrantsArea: 'hydrantsArea',
    hydrantsRefInstallationVerificationLimitId: {
      fieldName: 'hydrantsVerificationLimit',
      type: 'RefVerificationLimit'
    },
    hydrantsMissionPerimeterHasMeasure: 'hydrantsMissionPerimeterHasMeasure',
    hydrantsIsPublic: 'hydrantsIsPublic',
    hydrantsIsPrivate: 'hydrantsIsPrivate',
    hydrantsIsPublicInPrivate: 'hydrantsIsPublicInPrivate',
    hydrantsCommisioningYear: 'hydrantsCommisioningYear',
    peaCommisioningYear: 'peaCommisioningYear',
    peaTechnicalFileId: {
      fieldName: 'peaTechnicalFile',
      type: 'TechnicalFile'
    },
    peaArea: 'peaArea',
    peaRefInstallationVerificationLimitId: {
      fieldName: 'peaVerificationLimit',
      type: 'RefVerificationLimit'
    },
    peaNumber: 'peaNumber',
    peaMissionPerimeterHasMeasure: 'peaMissionPerimeterHasMeasure',
    peaVolumes: 'peaVolumes',
    peaIsPublic: 'peaIsPublic',
    peaIsPrivate: 'peaIsPrivate',
    peaIsPublicInPrivate: 'peaIsPublicInPrivate',
    penCommisioningYear: 'penCommisioningYear',
    penTechnicalFileId: {
      fieldName: 'penTechnicalFile',
      type: 'TechnicalFile'
    },
    penArea: 'penArea',
    penRefInstallationVerificationLimitId: {
      fieldName: 'penVerificationLimit',
      type: 'RefVerificationLimit'
    },
    penMissionPerimeterHasMeasure: 'penMissionPerimeterHasMeasure',
    penPublic: 'penIsPublic',
    penPrivate: 'penIsPrivate',
    penPublicInPrivate: 'penIsPublicInPrivate',
    groundHeightAspirationAxis: 'groundHeightAspirationAxis',
    columnsInterval: 'columnsInterval',
    columnNumber: 'columnNumber',
    zfNumber: 'zfNumber',
    mechanicalSmokeExtractionMissionPerimeterHasMeasure: 'mechanicalSmokeExtractionMissionPerimeterHasMeasure',
    fireExtinguisher: 'fireExtinguisher',
    checkValveLocation: 'checkValveLocation',
    damValveCityWaterLocation: 'damValveCityWaterLocation',
    damValveSceneCurtainLocation: 'damValveSceneCurtainLocation',
    counterDamValveLocation: 'counterDamValveLocation',
    maneuverValveSceneSideLocation: 'maneuverValveSceneSideLocation',
    maneuverValveExceptSceneLocation: 'maneuverValveExceptSceneLocation',
    emptyingValveLocation: 'emptyingValveLocation',
    emulsifierAdditiveType: 'emulsifierAdditiveType',
    zcNumber: 'zcNumber',
    warningOtherDescription: 'warningOtherDescription',
    functionOtherDescription: 'functionOtherDescription',
    repetitionEquipmentOtherDescription: 'repetitionEquipmentOtherDescription',
    alarmTypology: 'alarmTypology',
    refInstallationSsiTypologyId: {
      fieldName: 'refSsiTypology',
      type: 'RefSsiTypology'
    },
    refInstallationRemoteControlOriginOtherDescription: 'refRemoteControlOriginOther',
    associatedSubservienceHasVentilationCut: 'associatedSubservienceHasVentilation',
    associatedSubservienceOtherDescription: 'associatedSubservienceOtherDescription',
    isDeleted: 'isDeleted',
    isNew: 'isNew',
    notes: {
      type: 'Note',
      fieldName: 'notes',
      map: true,
      mapType: 'many',
    },
    sansObjets: {
      type: 'So',
      fieldName: 'sansObjets',
      map: true,
      mapType: 'many',
    },
    observations: {
      type: 'Observation',
      fieldName: 'observations',
      map: false,
      mapType: 'many',
    },
    technicalFiles: {
      type: 'TechnicalFile',
      fieldName: 'technicalFile',
      map: true,
      mapType: 'many',
    },
    installationRefInstallations: {
      type: 'RefInstallation',
      fieldName: 'refInstallations',
      map: false,
      mapToApi: true,
      mapType: 'many',
    },
    autoTelecommandTypologyOther: 'autoTelecommandTypologyOther'
  };

  @PrimaryGeneratedColumn()
  id: number;

  @Column({ nullable: true })
  @Index()
  extId: number;

  @ManyToMany(() => RefInstallation, refInstallation => refInstallation.installations)
  @JoinTable()
  refInstallations: RefInstallation[];

  @ManyToOne(() => Verification)
  @JoinColumn()
  verification: Verification;

  @ManyToOne(() => Building, b => b.installations)
  @JoinColumn()
  building: Building;

  @ManyToOne(() => RefAlertTypology)
  refAlertTypology: RefAlertTypology;

  @OneToMany(() => Note, n => n.installation, { cascade: true })
  notes: Note[];

  @OneToMany(() => So, so => so.installation, { cascade: true })
  sansObjets: So[];

  @ManyToMany(() => Observation, o => o.installations)
  observations: Observation[];


  @Column({ nullable: true })
  installationTypologyOther: string;

  @ManyToOne(() => RefInstallationFamily)
  refInstallationFamily: RefInstallationFamily;

  @Column({ nullable: true })
  familyName: string;

  @Column({ nullable: true })
  familyNameSearch: string;

  @Column({ nullable: true })
  area: string;

  @Column({ nullable: true })
  hasConformityDocuments: boolean;

  @Column({ nullable: true })
  presenceColonneFix: boolean;

  @Column({ nullable: true })
  conformityDocumentsShownDate: string;

  @Column({ nullable: true })
  reportedModificationsOnConformity: string;

  @Column({ nullable: true })
  featureAndConceptionExemption: string;

  @ManyToOne(() => RefVerificationLimit)
  refVerificationLimit: RefVerificationLimit;

  @Column({ nullable: true })
  commisioningYear: string;

  @Column({ nullable: true })
  hasLid: boolean;

  @Column({ nullable: true })
  otherDocumentsPresented: string;

  @Column({ nullable: true })
  otherInformation: string;

  @Column({ nullable: true })
  zaNumber: number;

  @Column({ nullable: true })
  zdaNumber: number;

  @Column({ nullable: true })
  zdmNumber: number;

  @Column({ nullable: true })
  delay: number;

  @ManyToOne(() => RefRemoteControlOrigin)
  refRemoteControlOrigin: RefRemoteControlOrigin;

  @Column({ nullable: true })
  productCharacteristics: string;

  @Column({ nullable: true })
  fogWaterTypology: string;

  @ManyToOne(() => RefSwitchType)
  refSwitchType: RefSwitchType;

  @Column({ nullable: true })
  rechargeDeviceCharacteristics: string;

  @Column({ nullable: true })
  powerSourceCharacteristics: string;

  @ManyToOne(() => RefMissionArea)
  refMissionArea: RefMissionArea;

  @ManyToOne(() => RefSurveillanceTypology)
  refSurveillanceTypology: RefSurveillanceTypology;

  @Column({ nullable: true })
  deciHasHydrants: boolean;

  @Column({ nullable: true })
  deciHasFixedColumns: boolean;

  @Column({ nullable: true })
  deciHasPea: boolean;

  @Column({ nullable: true })
  deciHasPen: boolean;

  @Column({ nullable: true })
  fixedColumnsCommisioningYear: string;

  @ManyToMany(() => TechnicalFile, technicalFile => technicalFile.installations)
  @JoinTable()
  technicalFile: TechnicalFile[];

  @ManyToMany(() => TechnicalFile)
  @JoinTable()
  fixedColumnTechnicalFile: TechnicalFile[];

  @Column({ nullable: true })
  hydrantsCommisioningYear: string;

  @ManyToMany(() => TechnicalFile)
  @JoinTable()
  hydrantsTechnicalFile: TechnicalFile[];

  @Column({ nullable: true })
  hydrantsArea: string;

  @ManyToOne(() => RefVerificationLimit)
  hydrantsVerificationLimit: RefVerificationLimit;

  @Column({ nullable: true })
  hydrantsMissionPerimeterHasMeasure: boolean;

  @Column({ nullable: true })
  hydrantsIsPublic: boolean;

  @Column({ nullable: true })
  hydrantsIsPrivate: boolean;

  @Column({ nullable: true })
  hydrantsIsPublicInPrivate: boolean;

  @Column({ nullable: true })
  peaCommisioningYear: string;

  @ManyToMany(() => TechnicalFile)
  @JoinTable()
  peaTechnicalFile: TechnicalFile[];

  @Column({ nullable: true })
  peaArea: string;

  @ManyToOne(() => RefVerificationLimit)
  peaVerificationLimit: RefVerificationLimit;

  @Column({ nullable: true })
  peaNumber: number;

  @Column({ nullable: true })
  peaMissionPerimeterHasMeasure: boolean;

  @Column({ nullable: true })
  peaVolumes: string;

  @Column({ nullable: true })
  peaIsPublic: boolean;

  @Column({ nullable: true })
  peaIsPrivate: boolean;

  @Column({ nullable: true })
  peaIsPublicInPrivate: boolean;

  @Column({ nullable: true })
  penCommisioningYear: string;

  @ManyToMany(() => TechnicalFile)
  @JoinTable()
  penTechnicalFile: TechnicalFile[];

  @Column({ nullable: true })
  penArea: string;

  @ManyToOne(() => RefVerificationLimit)
  penVerificationLimit: RefVerificationLimit;

  @Column({ nullable: true })
  penNumber: number;

  @Column({ nullable: true })
  penMissionPerimeterHasMeasure: boolean;

  @Column({ nullable: true })
  penVolumes: string;

  @Column({ nullable: true })
  penIsPublic: boolean;

  @Column({ nullable: true })
  penIsPrivate: boolean;

  @Column({ nullable: true })
  penIsPublicInPrivate: boolean;

  @Column({ nullable: true })
  groundHeightAspirationAxis: number;

  @Column({ nullable: true })
  columnsInterval: number;

  @Column({ nullable: true })
  columnNumber: number;

  @Column({ nullable: true })
  zfNumber: number;

  @Column({ nullable: true })
  mechanicalSmokeExtractionMissionPerimeterHasMeasure: boolean;

  @Column({ nullable: true })
  fireExtinguisher: string;

  @Column({ nullable: true })
  checkValveLocation: string;

  @Column({ nullable: true })
  damValveCityWaterLocation: string;

  @Column({ nullable: true })
  damValveSceneCurtainLocation: string;

  @Column({ nullable: true })
  counterDamValveLocation: string;

  @Column({ nullable: true })
  maneuverValveSceneSideLocation: string;

  @Column({ nullable: true })
  maneuverValveExceptSceneLocation: string;

  @Column({ nullable: true })
  emptyingValveLocation: string;

  @Column({ nullable: true })
  emulsifierAdditiveType: string;

  @Column({ nullable: true })
  zcNumber: number;

  @Column({ nullable: true })
  warningOtherDescription: string;

  @Column({ nullable: true })
  functionOtherDescription: string;

  @Column({ nullable: true })
  repetitionEquipmentOtherDescription: string;

  @Column({ nullable: true })
  alarmTypology: string;

  @ManyToOne(() => RefSsiTypology)
  refSsiTypology: RefSsiTypology;

  @Column({ nullable: true })
  refRemoteControlOriginOther: string;

  @Column({ nullable: true })
  associatedSubservienceHasVentilation: boolean;

  @Column({ nullable: true })
  associatedSubservienceOtherDescription: string;

  @Column({ nullable: true })
  isDeleted: boolean;

  @Column({ nullable: true })
  isNew: boolean;

  @Column({ nullable: true })
  autoTelecommandTypologyOther: string;

  async fromApi(data: any, update = false) {
    await super.fromApi(data, update);
    this.familyNameSearch = Utils.removeAccents(this.familyName);

    if (data.installationRefInstallations != null) {
      this.refInstallations = [];
      const refInstllationIds = data.installationRefInstallations.map(e => e.refInstallationId);
      const refinstallations = await getRepository(RefInstallation).find({
        where: {
          id: In(refInstllationIds)
        }
      });
      this.refInstallations = refinstallations;
    }

    return this;
  }
}
