import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';
import { BaseEntity } from './BaseEntity';

@Entity('nomenclature')
export class Nomenclature extends BaseEntity {
  apiMapping = {
      noId: 'noId',
      noLibelle: 'noLibelle',
  };

  @PrimaryGeneratedColumn()
  noId: number;

  @Column()
  noLibelle: string;

  @Column({ nullable: true })
    isDeleted: boolean;
}
