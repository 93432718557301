import { Column, Entity, Index, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { BaseEntity } from './BaseEntity';
import { RefChapterRubric } from './RefChapterRubric';
import { Installation } from './Installation';

@Entity('so')
export class So extends BaseEntity {
  apiMapping = {
      id: 'extId',
      // installationId: {
      //     type: 'Installation',
      //     fieldName: 'installation'
      // },
      refChapteRubricId: {
        type: 'RefChapterRubric',
        fieldName: 'refChapterRubric'
      }
  };

  @PrimaryGeneratedColumn()
  id: number;

  @ManyToOne(type => Installation, ins => ins.sansObjets)
  installation: Installation;

  @Column({ nullable: true })
  @Index()
  extId: number;

  // @Column()
  // SOXRefChapterId: number;

  // @Column()
  // SOXRefRubricId: number;

  @ManyToOne(type => RefChapterRubric)
  refChapterRubric: RefChapterRubric;
}
