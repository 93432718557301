import { Column, Entity, ManyToOne, PrimaryGeneratedColumn, Relation } from 'typeorm';
import { RefChapterRubric } from './RefChapterRubric';
import { RefTheme } from './RefTheme';
import { BaseEntity } from './BaseEntity';
import { Installation } from './Installation';

@Entity('ref_observation')
export class RefObservation extends BaseEntity {
  apiMapping = {
      id: 'id',
      name: 'longName',
      shortname: 'shortName',
      code: 'code',
      order: 'ordre',
      priority: 'priority',
      solution: 'solution',
      isDeleted: 'isDeleted',
      refThemeId: {
        fieldName: 'refTheme',
        type: 'RefTheme'
      },
      refChapterRubricId: {
        fieldName: 'refChapterRubric',
        type: 'RefChapterRubric'
      }
  };

  @PrimaryGeneratedColumn()
  id: number;

  @ManyToOne(() => RefTheme)
  refTheme: RefTheme;

  @ManyToOne(() => RefChapterRubric)
  refChapterRubric: RefChapterRubric;

  @Column({ nullable: true })
  longName: string;

  @Column({ nullable: true })
  shortName: string;

  @Column({ nullable: true })
  code: string;

  @Column({ nullable: true })
  ordre: number;

  @Column({ nullable: true })
  solution: string;

  @Column({ nullable: true })
  priority: number;

  @Column({ nullable: true })
  isDeleted: boolean;

  @Column({ nullable: true })
  local: boolean;

  @ManyToOne(type => Installation)
  installation: Installation;
}
