import { Column, Entity, Index, JoinColumn, ManyToMany, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { Building } from './Building';
import { BaseEntity } from './BaseEntity';
import { Verification } from './Verification';
import { Observation } from './Observation';

@Entity('level')
export class Level extends BaseEntity {
  apiMapping = {
    id: 'extId',
    localId: 'id',
    name: 'name',
    isDeleted: 'isDeleted',
    isNew: 'isNew',
    observations: {
      type: 'Observation',
      fieldName: 'observations',
      map: false,
      mapType: 'many',
    }
  };

  @PrimaryGeneratedColumn()
  id: number;

  @ManyToOne(type => Building, b => b.levels)
  @JoinColumn()
  building: Building;

  @ManyToMany(type => Observation, o => o.levels)
  observations: Observation[];

  @ManyToOne(type => Verification, verif => verif.levels)
  @JoinColumn()
  verification: Verification;

  @Column({ nullable: true })
  @Index()
  extId: number;

  @Column({ nullable: true })
  name: string;

  @Column({ nullable: true })
  isDeleted: boolean;

  @Column({ nullable: true })
  isNew: boolean;
}
