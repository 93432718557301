
export class Utils {
    /**
     * Permet de retirer les acceents
     * @param s Chaine de caratère sur laquelle retirer les accents
     */
    static removeAccents(s: string) {
      const non_asciis = {
          a: '[aàáâãäå]',
          c: '[cç]',
          e: '[eèéêë]',
          i: '[iìíîï]',
          n: '[nñ]',
          o: '[oòóôõö]',
          u: '[uùúûűü]',
          y: '[yýÿ]',
      };
      for (const i in non_asciis) {
        s = s.replace(new RegExp(non_asciis[i], 'g'), i);
      }

      return s;
    }
}
